<template>
  <div class="CartItems">
    <h2 class="o-default-h2 u-font-heading-light cart-item-title">
      {{ $t('cartStepper.items') }}
    </h2>
    <div class="grid-cart">
      <div class="card-items md-elevation-2" v-click-outside="closeContent">
        <Loader v-if="isCartLoading" />
        <div v-else>
          <section class="cart-item" v-for="item in this.entries" :key="item.id">
            <md-card>
              <md-card-content class="md-layout md-gutter card-content">
                  <div class="md-layout left-content">
                    <h3 class="o-default-h3">{{ item.productName }}</h3>
                    <h5 class="o-default-h5 product-info">
                      {{ $t('cartItems.text' + Helpers.capitalizeEachWord(item.productInfo)) }}
                    </h5>
                    <md-field class="card-field u-font-body-regular" v-if="item.productInfo === 'RENEW' || item.productInfo === 'REGISTER'">
                      <label>{{ $t('cartItems.period') }}</label>
                      <md-select class="select-quantity"
                                 v-model="item.productQuantity"
                                 name="quantity"
                                 id="quantity"
                                 @md-selected="updateQuantity(item.id, item.productQuantity)">
                        <md-option v-for="i in item.maxQuantity" :key="i" :value="i">{{ i }} {{ $tc('cartItems.years', i)}}</md-option>
                      </md-select>
                    </md-field>
                    <div class="card-caption u-font-body-regular o-default-body">
                      {{ $t('cartItems.text' + Helpers.capitalizeEachWord(item.productInfo)) }}
                      {{ $t('cartItems.for') }} {{ $t('cartItems.euroSign') }}{{ item.priceEuroNoVat.toFixed(2) }}/{{ $tc('cartItems.years', 1) }}
                    </div>
                    <div class="cart-price-green">
                      {{ $t('cartItems.euroSign') }}{{ item.totalEntryPriceNoVatEuro.toFixed(2) }}
                    </div>
                  </div> <!-- /.md-layout -->
                  <div class="md-layout right-content">
                    <div :class="{'pushed-left': isContentOpen[item.id]}">
                      <md-button class="md-icon-button" @click="toggleContents(item.id)" @touchstart="toggleContents(item.id)">
                        <md-icon>edit</md-icon>
                        <md-tooltip md-direction="left">{{ tooltipContent[item.id] }}</md-tooltip>
                      </md-button>
                    </div>
                    <div class="card-hidden-content u-cursor-pointer" @click="deleteDomainCart(item); showCopyConfirmation();" :class="{ 'contents-open': isContentOpen[item.id] }">
                      <md-button class="md-icon-button md-16">
                        <md-icon class="delete-button">delete_outline</md-icon>
                        <md-tooltip md-direction="left">{{ $t('cartItems.delete') }}</md-tooltip>
                      </md-button>
                    </div>
                  </div> <!-- /.md-layout -->
              </md-card-content>
            </md-card>
          </section>
          <!-- Uncomment this section when it will be implemented -->
          <!--
            <section class="cart-actions">
              <md-card-content>
                <div class="md-layout md-gutter">
                  <div class="discount-code md-layout">
                    <md-button>
                      <span class="md-caption u-text-underline">{{ $t('cartItems.voucher') }}</span>
                    </md-button>
                  </div> /.discount-code
                  <div class="empty-cart">
                    <md-button>
                      <md-icon class="md-8">delete</md-icon>
                      <span class="md-caption u-text-underline">{{ $t('cartItems.emptyCart') }}</span>
                    </md-button>
                  </div> /.empty-cart.
                </div> /.md-layout
              </md-card-content>
            </section>
          -->
        </div>
      </div>
      <div class="summary-layout md-elevation-2">
        <div class="cart-item-title summary-layout-header">
          <h3 class="o-default-h3">{{ $t('cartItems.paymentSummary') }}</h3>
        </div>
        <section class="cart-subtotal">
          <div class="card-content u-strong">
            <span>{{ $t('cartItems.subTotal') }}</span>
            <span class="cart-prices u-strong">{{ $t('cartItems.euroSign') }}{{ this.totalAmountEuroNoVat.toFixed(2) }}</span>
          </div>
          <div class="card-content u-strong">
            <span>{{ $t('cartItems.taxes') }}</span>
            <span class="cart-prices u-strong">{{ $t('cartItems.euroSign') }}{{ this.totalTaxEuro.toFixed(2) }}</span>
          </div>
        </section>
        <hr>
        <section class="cart-total">
          <div class="card-content">
            <span class="cart-text-total">{{ $t('cartItems.total') }}</span>
            <span class="cart-price-total cart-prices">{{ $t('cartItems.euroSign') }}{{ this.totalAmountEuro.toFixed(2) }}</span>
          </div>
          <router-link class="o-default-body-xs nav-button" :to="links.billing">
            <md-button class="md-raised md-primary continue-btn">{{ $t('common.continue') }}</md-button>
          </router-link>
        </section>
      </div>
    </div>
    <md-snackbar :md-position="snackbar.position"
                 :md-duration="snackbar.duration"
                 :md-active.sync="snackbar.showSnackbar"
                  md-persistent>
      <span>{{ $t('minicart.delete') }}</span>
      <md-button class="md-primary" @click="snackbar.showSnackbar = false">{{ $t('common.actions.close') }}</md-button>
    </md-snackbar>
  </div> <!-- /.CartItems -->
</template>

<script>
import { mapGetters } from 'vuex';
import {
  DELETE_ENTRY,
  UPDATE_PRODUCT_QUANTITY
} from '@/store/actions.type';
import Loader from '@/components/util/Loader';

export default {
  name: 'CartItems',
  components: {
    Loader
  },
  data() {
    return {
      items: [],
      links: {
        billing: '/cart/billing',
      },
      isContentOpen: {},
      tooltipContent: {},
      snackbar: {
        showSnackbar: false,
        duration: 4000,
        position: 'center',
      },
    };
  },
  computed: {
    ...mapGetters([
      'entries',
      'isCartLoading',
      'totalAmountEuro',
      'totalAmountEuroNoVat',
      'totalTaxEuro',
    ])
  },
  mounted() {
    this.entries.forEach(item => {
      this.$set(this.isContentOpen, item.id, false);
      this.$set(this.tooltipContent, item.id, this.$t('common.actions.edit'));
    });
  },
  methods: {
    showCopyConfirmation() {
      this.snackbar.showSnackbar = true;
    },
    updateQuantity(id, quantity) {
      let item = {
        id: id,
        period: quantity,
      };
      this.$store.dispatch(UPDATE_PRODUCT_QUANTITY, item);
    },
    deleteDomainCart(item) {
      let slug = "?entryId=" + item.id;
      this.$store.dispatch(DELETE_ENTRY, slug);
    },
    toggleContents(itemId) {
      this.$set(this.isContentOpen, itemId, !this.isContentOpen[itemId]);
      this.$set(this.tooltipContent, itemId, this.isContentOpen[itemId] ? this.$t('common.actions.undo') : this.$t('common.actions.edit'));
    },
    closeContent() {
      for (const itemId in this.isContentOpen) {
        this.$set(this.isContentOpen, itemId, false);
        this.$set(this.tooltipContent, itemId, this.$t('common.actions.undo'));
      }
    },
  }
};
</script>

<style lang="scss">
.CartItems {

  .continue-btn .md-button-content {
    font-size: $type-scale-size-6;
  }
}
</style>

<style lang="scss" scoped>
.CartItems {

  .continue-btn {
    display: block;
    margin: 2rem auto 1rem;
    min-height: 4.8rem;
    padding: 1rem 0;
    width: 90%;
  }

  .card-hidden-content {
    background-color: $palette-error;
    height: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    transition: width .5s ease;
    width: 0;
  }

  .contents-open {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 7.2rem;
  }

  .pushed-left {
    transform: translateX(-60px);
  }

  .grid-cart {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(1, 1fr);
    @include desktop {
      grid-template-columns: 3.2fr 1.6fr;
      gap: 3rem;
    }

    .summary-layout {
      background-color: $white;
      font-weight: 500;
      margin-bottom: 1rem;
      min-width: 100%;
      max-height: 30rem;
      padding: 1rem 0;
    }
  }

  .cart-item-title {
    text-align: center;
    @include desktop {
      text-align: left;
    }
  }

  .select-quantity {
    min-width: 8rem;
  }

  .card-content {
    display: flex;
    justify-content: space-between;
    padding: 0.8rem 2.4rem;
    text-align: left;
  }

  .card-field {
    max-width: 16rem;
    margin-bottom: 1rem;
  }

  .card-caption {
    min-width: 100%;
  }

  .card-items {
    background-color: transparent;
    box-shadow: none;
    min-width: 100%;
  }

  .cart-item {
    margin-bottom: 1.6rem;
    min-height: 15rem;
  }

  .cart-actions {
    .discount-code {
      text-align: center;
      @include desktop {
        text-align: left;
      }
    }
    .empty-cart {
      text-align: center;
      @include desktop {
        text-align: right;
      }
    }
  }

  .delete-button {
    color: $white;
  }

  .cart-prices {
    float: right;
    font-weight: 500;
  }

  .cart-price-green {
    color: $palette-primary-color-two;
    font-size: 2.2rem;
    font-weight: 600;
    margin-top: 1rem;
  }

  .cart-price-total {
    font-size: 2rem;
    font-weight: 600;
  }

  .cart-subtotal {
    min-width: 100%;
    margin: 1.2rem 0;
  }

  .cart-text-total {
    font-size: 2rem;
    font-weight: bold;
  }

  .cart-total {
    margin: 1.2rem 0;
  }

  .summary-layout-header {
    padding: 1rem 2.4rem .8rem;
  }

  .left-content {
    display: flex;
    flex: 5;
    flex-wrap: wrap;
    padding: 1.2rem;
  }

  .right-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-end;
    padding: 1.2rem;
  }

  .product-info {
    width: 100%;
  }
}
</style>